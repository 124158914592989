require("bootstrap/dist/css/bootstrap.min.css")
require("bootstrap/dist/js/bootstrap.min.js")
require("@popperjs/core/dist/umd/popper.min.js")
require("bootstrap-icons/font/bootstrap-icons.css")

exports.shouldUpdateScroll = ({
    routerProps,
    getSavedScrollPosition,
  }) => {
    const { pathname } = routerProps.location
    // list of routes for the scroll-to-top-hook
    const scrollToTopRoutes = [`/workflow/single-tooth`, `/workflow/partial`, `/workflow/full-arch`]
    // if the new route is part of the list above, scroll to top (0, 0)
    if (scrollToTopRoutes.indexOf(pathname) !== -1) {
      window.scrollTo(0, 0)
    }
    const isHash = routerProps.location.hash;
    const gatsbyWrapper = document.getElementById('gatsby-focus-wrapper');
    /* Do not scroll top if the route contain a hash */
    gatsbyWrapper && !isHash && (gatsbyWrapper.scrollTop = 0);

    return isHash && isHash.replace('#', '');
}